html {
	background-image: linear-gradient(#361c32, #0e171b);
	align-items: center;
}
.MenuBar {
	position: -webkit-sticky;
	background-color: white;
	position: sticky;
	top: 0;
}
.About {
	padding: 2em;
	margin: 1em;
	margin-bottom: 2.5em;
	margin-top: 2.5em;
}
.profileCard {
	padding: 2em;
	text-align: center;
}
.profilePicture {
	padding: 2em;
	align-self: center;
}
.projectContainer,
.experienceContainer {
	margin-left: 2em;
	margin-right: 2em;
	margin-bottom: 2em;
}
.workExperience,
.projects {
	padding-left: 1em;
	padding-top: 1em;
}

.card {
	text-align: center;
	height: 400px;
	padding: 2;
	align-items: "center";
	margin: "auto";
}

.cardImg {
	margin-top: 1em;
}

.chipStack {
	margin: auto;
}

.subtitle {
	padding: 0em;
}
.desc {
	padding: 1em;
}
